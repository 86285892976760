
import { getYxList } from './../api'
import { getDLlnumSeach, getActivityInfo, getMatklByShop,getActivitySaleInfo } from './api'
import { saveActivity } from './../addOredit/api'
import axios from "axios";
import { publicPath } from "@/common/constant.js";
import moment from 'moment';
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  name: "",
  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
      {
        path: "/storeActivities",
        name: "storeActivities",
        title: "专卖店市场活动"
      },
      {
        path: "/storeActivities/editOredit",
        name: "addOredit",
        title: "活动编辑"
      }
      ],
      pageLoadFlag: false,
      detilInfo: {},
      columns: [
        {
          title: "物料组",
          dataIndex: "matklName",
          width: 400,
          scopedSlots: { customRender: 'type' },
        },
        {
          title: "预计销售额（万元）",
          dataIndex: "planSaleMoney",
          width: 400,
          scopedSlots: { customRender: 'num' },
        },
      ],
      selectedRowKeys: [],
      tableData: [], // 物料组数据
      fileList: [],// 认筹毒箭
      fileIds: '', // 认筹附件id
      activefileList: [],// 活动方案附件
      activefileId: '',// 活动方案id
      saleList: [],// 销售现场附件
      saleFileId: '',// 销售现场附件id
      shareList: [],// 经验分享附件
      shareFileId: '',// 经验分享附件id
      previewVisible: false,
      previewImage: '',
      marketCenterList: [], // 营销中心数据
      marketCenterId: '', //营销中心id
      marketCenter: '',
      officeName: '',// 办事处
      officeId: '', // 办事处id
      timeStart: '', // 活动时间
      statusList: [], // 审批状态数据
      statusName: '', //审批状态
      FXDLlnum: '', // 模糊搜索 代理商id
      FXDLlist: [], // 模糊搜索出来的数据
      FXList: [],
      FXName: [],
      processInstId: '',
      FXID: '',
      therm: '',// 主题
      address: '',// 地址
      targetAmount: '', // 目标金额
      totalInvestment: "", // 投入合计
      advertisement: '',// 待制作广告
      visible: false,
      MarketTableList: [],// 带出来的物料组
      endTime: '', // 结束时间
      capzuoDisabled: false , // 编辑时表格是否能够操作
      dataSource:'',
      isCanEditAttachments:'',
      isCanDate:true,
      lastData:'',
      detailInfo:{},
      isLargeThanFive:false,
      isMoreThanFive:false
    };
  },
  mounted() {
    this.getHDType()
    if(this.$route.query.id) {
       this.getSignleInfo(this.$route.query.id)
      this.getOwner(this.$route.query.id)
    } else {
      this.$message.warning('查询详情失败,获取不到id')
    }
    this.dataSource = this.$route.query.dataSource
    this.isCanEditAttachments = this.$route.query.isCanEditAttachments
  },
  methods: {
    onGiveType(e){
      this.marketCenterId = e.target.value
    },
    // 下载文档
    downLoad(item){
      window.open(item.url, '_blank')
    },
    // 获取自己提交的数据
    getOwner(id){
      let data = {
        id:id
      }
      getActivitySaleInfo(data).then(res=>{
        if(res && res.data.code == 0) {
          res.data.data.forEach(item=>{
            this.tableData .push({
              matklId: item.matklId,
              matklName: item.matklName,
              planSaleMoney: item.planSaleMoney,
              isEdited:true
            })
          })
        } else {
          this.$message.warning('获取数据失败', res.data.msg)
        }
      }) 
    },
    // 开始时间选择不能选择今天之前的日期
    disabledDate(current) {
      return current && current < moment().endOf('day');
    },
    // 查询回显的物料组
    getMarktlwuliao(id) {
      let data = {
        shopId: id
      }
      getMatklByShop(data).then(res => {
        if (res.data.code === 0) {
          this.MarketTableList = res.data.data
        }
      })
    },

    //获取活动类别数据
    getHDType() {
      let data = {
        categoryName: 'specialActivityType'
      }
      getYxList(data).then(res => {
        this.marketCenterList = res.data.data
      })
    },
    CompareDate(d1,d2){
      return ((new Date(d1.replace(/-/g,"/"))) > (new Date(d2.replace(/-/g,"/"))));
    },
    dateAddDays(dateStr,dayCount) {
      var tempDate=new Date(dateStr.replace(/-/g,"/"));//把日期字符串转换成日期格式
      var resultDate=new Date((tempDate/1000+(86400*dayCount))*1000);//增加n天后的日期
      var resultDateStr=resultDate.getFullYear()+"-"+(resultDate.getMonth()+1)+"-"+(resultDate.getDate());//将日期转化为字符串格式
      return resultDateStr;
    },
    // 获取当前时间
    fun_date() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds();
      return yy + "-" + mm+ "-" + dd;
    },
    // 如果是编辑页面获取单条数据
    getSignleInfo(id) {
      let data = {
        id: id
      }
      getActivityInfo(data).then(res => {
        if (res.data && res.data.code == 0) {
          if(res.data.data.modifyCount >= 2  ){
            this.$message.warning('因活动修改次数达到上限，目前只允许修改附件')
            this.capzuoDisabled = true
          } else {
            this.capzuoDisabled = false
          }
          // 详情数据
          // 门店名称和门店id
          this.detailInfo = res.data.data
          this.dlId = res.data.data.shopInfoId
          this.getMarktlwuliao(this.dlId)
          this.FXDLlnum = res.data.data.shopInfoName
          // 活动主题
          this.address = res.data.data.activityTheme
          // 活动类别
          this.marketCenterId = res.data.data.activityLabelId.toString()
          // 开始时间 结束时间
          this.endTime = res.data.data.endTime
          this.timeStart = res.data.data.startTime

         this.lastData = this.dateAddDays(this.endTime,3)
          this.isCanDate =  this.CompareDate(this.fun_date(),this.lastData)
          // 活动方案附件
          if (res.data.data.hdfaFile) {
            this.activefileList.push(res.data.data.hdfaFile)
          }

          let aaa = []
          if (this.activefileList && res.data.data.hdfaFile) {
            this.activefileId = res.data.data.hdfaFile.id
            this.activefileList.forEach(item => {
              aaa.push(
                {
                  uid: item.id,
                  name: item.attachName,
                  status: "done",
                  url: item.attachPath,
                },
              );
            })
          }
          this.activefileList = aaa
          // 认筹附件
          if (res.data.data.rcxcFile) {
            this.fileList.push(res.data.data.rcxcFile)
          }

          let bbb = []
          if (this.fileList && res.data.data.rcxcFile) {
            this.fileIds = res.data.data.rcxcFile.id
            this.fileList.forEach(item => {
              bbb.push(
                {
                  uid: item.id,
                  name: item.attachName,
                  status: "done",
                  url: item.attachPath,
                },
              );
            })
          }
          this.fileList = bbb
          // 销售附件
          if (res.data.data.xsxcFile) {
            this.saleList.push(res.data.data.xsxcFile)
          }

          let ccc = []
          if (this.saleList && res.data.data.xsxcFile) {
            this.saleFileId = res.data.data.xsxcFile.id
            this.saleList.forEach(item => {
              ccc.push(
                {
                  uid: item.id,
                  name: item.attachName,
                  status: "done",
                  url: item.attachPath,
                },
              );
            })
          }
          this.saleList = ccc
          // 经验
          if (res.data.data.jyfxFile) {
            this.shareList.push(res.data.data.jyfxFile)
          }
          let ddd = []
          if (this.shareList && res.data.data.jyfxFile) {
            this.shareFileId = res.data.data.jyfxFile.id
            this.shareList.forEach(item => {
              ddd.push(
                {
                  uid: item.id,
                  name: item.attachName,
                  status: "done",
                  url: item.attachPath,
                },
              );
            })
          }
          this.shareList = ddd
        } else {
          this.$message.warning('获取数据失败', res.data.msg)
        }
      }).catch(err => {
        console.log('err');
      })
    },
    goback() {
      window.close()
      this.$router.push({ path: '/storeActivities' });
    },
    changeDl(value, e) {
      this.dlId = value
    },

    // 输入模糊搜索代理商
    getDLList(value) {
      this.FXDLlnum = value
      let data = {
        searchStr: this.FXDLlnum
      }
      getDLlnumSeach(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data)

        } else {
          data = res.data;
        }
        this.FXDLlist = [...new Set(data.data)]; //缓存列表数据，前端分页
      })
    },
    //查询数据
    search() {
      this.getMarktl()
    },
    getMarktl() {
      // this.tableData = []
      this.MarketTableList = [
        {
          type: '11',
          price: 0,
          id: 11111,
        },
        {
          type: '222',
          price: 2,
          id: 222222
        }
      ]
      this.MarketTableList.forEach(marItem => {
        this.tableData.push({
          type: marItem.type,
          num: marItem.price,
          id: marItem.id
        })
      })
    },
    // 更改营销
    handleChangeshopList(value, e) {
      //  更改选项的时候判断当前数据和接口数据的值是否一致 赋值新的id
      this.MarketTableList.forEach(marItem => {
        this.tableData.forEach(item => {
          if (marItem.matklName == item.matklName) {
            item.matklId = marItem.matklId
          }
        })
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 时间更改
    handleTimeChange(e, defaultTime) {
      this.timeStart = defaultTime;
    },
    // 时间更改
    handleendTimeChange(e, defaultTime) {
      this.endTime = defaultTime;
    },
    //获取营销zhongxin数据
    getYYXT() {
      let data = {}
      getYxList(data).then(res => {
        this.marketCenterList = res.data.data
      })
    },
    // 复制行数据
    copy() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请至少选择一项数据!')
      } else if (this.selectedRowKeys.length > 1) {
        this.$message.warning('只能一条数据进行复制!')
      } else {
        let aa = {}
        this.tableData.forEach((item, index) => {
          this.selectedRowKeys.forEach(i => {
            if (index == i) {
              aa = {
                isEdited: true,
                matklId:  this.tableData[index].matklId,
                matklName:  this.tableData[index].matklName,
                planSaleMoney:  this.tableData[index].planSaleMoney,
               
              }
            }
          })
        })
        this.tableData.push(aa)
      }
    },
    //删除行数据
    delLine() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请至少选择一项数据')
      } else {
        this.tableData = this.tableData.filter((item, index) => !this.selectedRowKeys.includes(index))
        this.selectedRowKeys = []
      }
    },
    // 勾选数据
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 添加行
    addline() {
      this.tableData.push(
        {
          matklName: "",
          matklId: "",
          planSaleMoney: 0,
          isEdited: true
        }
      )
    },
    // 认筹上传附件
    async beforeUpload(file,fileList) {
      // console.log("fileList的长度是",fileList?.length);
      // console.log("fileList是",fileList);
      // console.log('当前file是',file);
      // console.log("当前file的size属性值是",file.size);
      if (fileList?.length>5) {
        if (file.uid==fileList[fileList?.length-1].uid) {
          this.$message.warning('最多上传五个附件')
          return false
        }else{
          return false
        }
      }
      const newFile = await this.compressImg(file)
      // 上传附件   调接口
      let formData = new FormData()
      formData.append('file', newFile);
      const BASE_API = publicPath;
      let config = {
        headers: { 'content-type': 'multipart/form-data', },
      }
      this.pageLoadFlag = true
      axios.post(BASE_API + '/custAgentActivity/uploadFile.nd', formData, config).then(res => {
        let resResult = res.data
        if (resResult.message == '上传成功') {
          this.fileList = [...this.fileList, newFile]
          // this.fileList = this.fileList.slice(-1);
          this.fileList.forEach(item => {
            item.size2 = ((item.size) / 1024).toFixed(2)
          })
          this.fileIds = res.data.businessId
          this.pageLoadFlag = false
        } else {
          this.fileList = []
          this.$message.warning(resResult.msg, 3)
          this.pageLoadFlag = false
          return
        }
      })
      return false;
    },    
    // base64转码（压缩完成后的图片为base64编码，这个方法可以将base64编码转回file文件）
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 图片压缩函数
    compressImg (file) {
      const that = this
      var files
      var fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2)
      var read = new FileReader()
      read.readAsDataURL(file)
      return new Promise(function (resolve, reject) {
        read.onload = function (e) {
          var img = new Image()
          img.src = e.target.result
          img.onload = function () {
            // 默认按比例压缩
            var w = this.width
            var h = this.height
            // 生成canvas
            var canvas = document.createElement('canvas')
            var ctx = canvas.getContext('2d')
            var base64
            // 创建属性节点
            canvas.setAttribute('width', w)
            canvas.setAttribute('height', h)
            ctx.drawImage(this, 0, 0, w, h)
            // if (fileSize < 0.5) {
            //   // 如果图片小于一兆 那么压缩0.5
            //   console.log("图片小于0.5M");
            //   base64 = canvas.toDataURL(file['type'], 0.5)
            // } else 

            // if (fileSize >= 0.5 && fileSize < 1) {
            //   // 如果图片大于1M并且小于2M 那么压缩0.5
            //   console.log("图片超过0.5M小于1M");
            //   base64 = canvas.toDataURL(file['type'], 0.9)
            // } else if (fileSize >= 1 && fileSize < 2) {
            //   // 如果图片大于2M并且小于3M 那么压缩0.5
            //   console.log("图片超过1M小于2M");
            //   base64 = canvas.toDataURL(file['type'], 0.2)
            // } else if (fileSize >= 2 && fileSize < 3) {
            //   // 如果图片大于3M并且小于4M 那么压缩0.5
            //   console.log("图片超过2M小于3M");
            //   base64 = canvas.toDataURL(file['type'], 0.15)
            // } else if (fileSize >= 3 && fileSize < 4) {
            //   // 如果图片大于3M并且小于4M 那么压缩0.5
            //   console.log("图片超过3M小于4M");
            //   base64 = canvas.toDataURL(file['type'], 0.075)
            // } else {
            //   console.log("图片超过4M");
            //   // 如果图片超过4m 那么压缩0.2
              base64 = canvas.toDataURL(file['type'], 0.7)
            // }
            // 回调函数返回file的值（将base64编码转成file）
            files = that.dataURLtoFile(base64, file.name) // 如果后台接收类型为base64的话这一步可以省略
            // console.log("base64########",base64)
            // console.log("files########",files)
            resolve(files)
          }
        }
      })
    },
    handleChange({ fileList }) {
      if (fileList?.length<=5) {
        // let fileList1 = fileList;
        // this.fileList = fileList1.slice(-1);
        this.fileList.forEach(item => {
          item.size2 = ((item.size) / 1024).toFixed(2)
        })
      }
    },
    handleRemove(file) {
      // let index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(file, 1);
      this.fileList = newFileList;
      this.fileIds = ''
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (file.url) {
        window.open(file.url)
      } else {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      }
    },
    handleDownload(file) {
      this.$message.warn('您点击了下载')
    },
    // 活动上传附件  附件2
    async beforeUpload2(file,fileList) {
      if (fileList?.length>5) {
        if (file.uid==fileList[fileList?.length-1].uid) {
          this.$message.warning('最多上传五个附件')
          return false
        }else{
          return false
        }
      }
      const newFile = await this.compressImg(file)
        // 上传附件   调接口
        let formData = new FormData()
        formData.append('file', newFile);
        const BASE_API = publicPath;
        let config = {
          headers: { 'content-type': 'multipart/form-data', },
        }
        this.pageLoadFlag = true
        axios.post(BASE_API + '/custAgentActivity/uploadFile.nd', formData, config).then(res => {
          let resResult = res.data
          if (resResult.message == '上传成功') {
            this.activefileList = [...this.activefileList, newFile]
            // this.activefileList = this.activefileList.slice(-1);
            this.activefileList.forEach(item => {
              item.size2 = ((item.size) / 1024).toFixed(2)
            })
            this.activefileId = res.data.businessId
            this.pageLoadFlag = false
          } else {
            this.activefileList = []
            this.$message.warning(resResult.msg, 3)
            this.pageLoadFlag = false
            return
          }
        })
        return false;
      // }
    },
    handleChange2({ fileList }) {
      if (fileList?.length<=5) {
      // let fileList1 = fileList;
      // this.activefileList = fileList1.slice(-1);
        this.activefileList.forEach(item => {
          item.size2 = ((item.size) / 1024).toFixed(2)
        })
      }
    },
    handleRemove2(file) {
      // const index = this.activefileList.indexOf(file);
      const newFileList = this.activefileList.slice();
      newFileList.splice(file, 1);
      this.activefileList = newFileList;
      // this.activefileId.splice(index, 1)
      this.activefileId = ''
    },

    //销售现场附件
    async beforeUpload3(file,fileList) {
      if (fileList?.length>5) {
        if (file.uid==fileList[fileList?.length-1].uid) {
          this.$message.warning('最多上传五个附件')
          return false
        }else{
          return false
        }
      }
      const newFile = await this.compressImg(file)
        // 上传附件   调接口
        let formData = new FormData()
        formData.append('file', newFile);
        const BASE_API = publicPath;
        let config = {
          headers: { 'content-type': 'multipart/form-data', },
        }
        this.pageLoadFlag = true
        axios.post(BASE_API + '/custAgentActivity/uploadFile.nd', formData, config).then(res => {
          let resResult = res.data
          if (resResult.message == '上传成功') {
            this.saleList = [...this.saleList, newFile]
            // this.saleList = this.saleList.slice(-1);
            this.saleList.forEach(item => {
              item.size2 = ((item.size) / 1024).toFixed(2)
            })
            this.saleFileId = res.data.businessId
            this.pageLoadFlag = false
            return
          } else {
            this.saleList = []
            this.$message.warning(resResult.msg, 3)
            this.pageLoadFlag = false
            return
          }
        })
        return false;
    },
    handleChange3({ fileList }) {
      if (fileList?.length<=5) {
        // let fileList1 = fileList;
      // this.saleList = fileList1.slice(-1);
        this.saleList.forEach(item => {
          item.size2 = ((item.size) / 1024).toFixed(2)
        })
      }
    },
    handleRemove3(file) {
      // const index = this.saleList.indexOf(file);
      const newFileList = this.saleList.slice();
      newFileList.splice(file, 1);
      this.saleList = newFileList;
      this.saleFileId = ''
    },
    // 经验分享附件上传
    async beforeUpload4(file,fileList) {
      if (fileList?.length>5) {
        if (file.uid==fileList[fileList?.length-1].uid) {
          this.$message.warning('最多上传五个附件')
          return false
        }else{
          return false
        }
      }
      const newFile = await this.compressImg(file)
        // 上传附件   调接口
        let formData = new FormData()
        formData.append('file', newFile);
        const BASE_API = publicPath;
        let config = {
          headers: { 'content-type': 'multipart/form-data', },
        }
        this.pageLoadFlag = true
        axios.post(BASE_API + '/custAgentActivity/uploadFile.nd', formData, config).then(res => {
          let resResult = res.data
          if (resResult.message == '上传成功') {
            this.shareList = [...this.shareList, newFile]
            // this.shareList = this.shareList.slice(-1);
            this.shareList.forEach(item => {
              item.size2 = ((item.size) / 1024).toFixed(2)
            })
            this.shareFileId = res.data.businessId
            this.pageLoadFlag = false
            return
          } else {
            this.shareList = []
            this.$message.warning(resResult.msg, 3)
            this.pageLoadFlag = false
            return
          }
        })
        return false;
    },
    handleChange4({ fileList }) {
      if (fileList?.length<=5) {
        // let fileList1 = fileList;
        // this.shareList = fileList1.slice(-1);
        this.shareList.forEach(item => {
          item.size2 = ((item.size) / 1024).toFixed(2)
        })
      }

    },
    handleRemove4(file) {
      // const index = this.shareList.indexOf(file);
      const newFileList = this.shareList.slice();
      newFileList.splice(file, 1);
      this.shareList = newFileList;
      this.shareFileId = ''
    },
    // 上传附件代码结束
    // 提交
    confim() {
      if (!this.dlId) {
        this.$message.warning('请选择门店')
        return
      }
      if (!this.address) {
        this.$message.warning('请填写活动主题')
        return
      }
      if (!this.marketCenterId) {
        this.$message.warning('请选择活动类别')
        return
      }
      if (!this.timeStart) {
        this.$message.warning('请选择开始时间')
        return
      }
      if (!this.endTime) {
        this.$message.warning('请选择结束时间')
        return
      }
      // 开始时间转换时间戳
      let timestr = this.timeStart.replace(/-/g, '/');
      let timestamp = new Date(timestr).getTime();
      //结束时间转换时间戳
      let endTimeStr = this.endTime.replace(/-/g, '/');
      let endTimeSj = new Date(endTimeStr).getTime();
      if (timestamp > endTimeSj) {
        this.$message.warning('开始时间不能大于结束时间，请重新选择')
        return
      }
      if(this.isCanDate && ((this.detailInfo.rcxcFile.id!==this.fileIds) || (this.detailInfo.xsxcFile.id!==this.saleFileId))) {
        this.$message.warning('活动结束后仅限三天时限上传【认筹宣传、销售现场】附件！')
        this.timer = setTimeout(() => {  //设置延迟执行
          this.$router.go(0)
        }, 2000);
        return
      }
      if (this.tableData && this.tableData.length > 0) {
        for (var i = 0; i < this.tableData.length; i++) {
          for (var j = i + 1; j < this.tableData.length; j++) {
            if (this.tableData[i].matklId == this.tableData[j].matklId) {
              this.$message.warning('相同物料组只能提交一组数据')
              return false;
            }
          }
        }
      }

      let data = {
        id: this.$route.query.id,
        shopInfoId: this.dlId,
        activityTheme: this.address,//活动主题
        activityLabelId: this.marketCenterId,//活动类别id
        startTime: this.timeStart,//开始时间
        endTime: this.endTime,//结束时间
        saleInfoList: this.tableData,
        rcxcAttach: this.fileIds, // 认筹附件
        hdfaAttach: this.activefileId,//活动方案附件
        xsxcAttach: this.saleFileId,//销售现场附件
        jyfxAttach: this.shareFileId,//经验分享附件

      }
      this.pageLoadFlag = true
      saveActivity(data).then(res => {
        if (res.data && res.data.code == 0) {
          this.$message.success(res.data.msg)
          window.opener.location.reload();
          this.pageLoadFlag = false
          this.timer = setTimeout(() => {  //设置延迟执行
            this.goback()
          }, 2000);
        } else {
          this.$message.warning(res.data.msg)
        }
      })
    }


  }

};